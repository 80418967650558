import React from 'react';
import SEO from '../../components/Seo/Seo';

const PrivacyPolicy = () => {
  const seoProps = {
    title: "Privacy Policy - The Blog Aura",
    description: "Read The Blog Aura's Privacy Policy to learn about how we handle your personal information, data collection, and usage practices. Your privacy is important to us.",
    keywords: "Privacy Policy, The Blog Aura, data privacy, personal information, data collection, cookies, user rights, data protection",
    url: "https://theblogaura.com/privacy-policy",
    image: "%PUBLIC_URL%/logo192.png" // Replace with your actual privacy policy image URL
  };
  
  return (
<>
<SEO
{...seoProps}
/>
<div className="bg-gray-100 text-gray-800 py-12 px-4 md:px-8 lg:px-16">
      <div className="container mx-auto max-w-4xl bg-white shadow-lg rounded-lg p-8">
        <h1 className="text-4xl font-extrabold text-teal-600 mb-6">Privacy Policy</h1>
        <p className="mb-4">
          Welcome to <strong>The Blog Aura</strong>. This Privacy Policy explains how we collect, use, and protect your information when you use our blog platform. By accessing or using our website, you agree to the terms of this policy.
        </p>

        <h2 className="text-2xl font-bold text-teal-500 mt-8 mb-4">1. Information We Collect</h2>
        <p className="mb-4">
          We do not collect personal information unless you provide it voluntarily, such as when you subscribe to our newsletter or engage with our content. 
          All content and data related to the blogs are managed by the admin.
        </p>

        <h2 className="text-2xl font-bold text-teal-500 mt-8 mb-4">2. Admin Control</h2>
        <p className="mb-4">
          The administration of <strong>The Blog Aura</strong> has full control over all content-related actions on the platform. The admin is responsible for creating, reading, updating, and deleting blog posts. User interactions with the blog posts, such as comments or likes, may also be monitored and managed by the admin.
        </p>

        <h2 className="text-2xl font-bold text-teal-500 mt-8 mb-4">3. Use of Information</h2>
        <p className="mb-4">
          The information provided by users, if any, is used solely for improving the content and experience on <strong>The Blog Aura</strong>. We do not share, sell, or disclose any personal information to third parties.
        </p>

        <h2 className="text-2xl font-bold text-teal-500 mt-8 mb-4">4. Cookies</h2>
        <p className="mb-4">
          Our website may use cookies to enhance the user experience. These cookies are used to track visitor preferences and activity solely to improve our website's performance. You can choose to disable cookies in your browser settings, although this may affect the functionality of the site.
        </p>

        <h2 className="text-2xl font-bold text-teal-500 mt-8 mb-4">5. Changes to this Privacy Policy</h2>
        <p className="mb-4">
          We reserve the right to update this Privacy Policy at any time. Changes will be posted on this page, and the "Last Updated" date at the top of the page will be revised. We encourage users to check this page periodically to stay informed of any updates.
        </p>

        <p className="text-gray-600 mt-8">
          Thank you for being a part of <strong>The Blog Aura</strong>. Your privacy is important to us, and we are committed to protecting your information.
        </p>
      </div>
    </div>
    </>
  );
};

export default PrivacyPolicy;
