import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import 'react-lazy-load-image-component/src/effects/blur.css';
import handleClick from '../../utils/HandelClick';
import { useNavigate } from 'react-router-dom';

const PostCard = ({ post, index }) => {
  const postRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    gsap.fromTo(
      postRef.current,
      { opacity: 0, x: 100 },
      {
        opacity: 1,
        x: 0,
        delay: index * 0.2,
        duration: 0.8,
        ease: 'power2.out',
      }
    );
  }, [index]);

  function formatDate(dateString) {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const formattedDate = new Date(dateString).toLocaleDateString(undefined, options);
    return formattedDate;
  }

  function truncateText(text, maxLength) {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  }

  return (
    <div
      ref={postRef}
      className="w-full p-4"
    >
      <div
        className="bg-white shadow-lg rounded-lg hover:shadow-2xl transition-shadow duration-300 transform hover:scale-105 cursor-pointer flex flex-col"
        onClick={() => handleClick(post, navigate)}
        style={{ width: '320px', height: '420px' }} // Adjust width and height here
      >
        <div className="h-48 rounded-t-lg overflow-hidden">
          <img
            src={post.thumbnail}
            alt={post.imageAltText}
            className="w-full h-full object-cover"
          />
        </div>
        <div className="flex-grow p-4 flex flex-col justify-between">
          <div>
            <h3 className="text-lg font-semibold">
              {post.title}
            </h3>
            <p className="text-sm text-gray-600 mt-2">
              {truncateText(post.metaDescription, 160)}
            </p>
          </div>
          <div className="flex justify-between items-center mt-4">
            <span className="text-teal-600 font-semibold">Blog Aura</span>
            <span className="text-gray-500 text-sm">{formatDate(post.createdAt)}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostCard;
