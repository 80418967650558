import React from 'react';

const OurValues = () => {
  return (
    <section className="py-12 bg-white">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold mb-6">Our Values</h2>
        <ul className="list-disc pl-5">
          <li className="mb-2">Integrity: We uphold the highest standards of honesty and transparency.</li>
          <li className="mb-2">Innovation: We continuously seek new and creative ways to provide value.</li>
        </ul>
      </div>
    </section>
  );
};

export default OurValues;
