import React from 'react';

const CategoryList = ({ categories, activeCategory, setActiveCategory,setCurrentPage }) => {
  return (
    <div className="py-7 px-6 overflow-x-auto custom-scrollbar flex space-x-4">
      {categories.map((category, index) => (
        <button
          key={index}
          onClick={() => {
            
            setActiveCategory(category.category);
            setCurrentPage(1)
            console.log("Selected Category is ", category.category);
          }}
          className={`flex-shrink-0 px-6 py-2 text-sm font-semibold rounded-full border-2 transition-colors duration-300 ${
            activeCategory === category.category
              ? 'bg-teal-600 text-white border-teal-600'
              : 'bg-white text-gray-700 border-gray-300 hover:bg-teal-500 hover:text-white hover:border-teal-500'
          }`}
        >
          {category.category}
        </button>
      ))}
    </div>
  );
};

export default CategoryList;
