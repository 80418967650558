import React, { useState, useEffect } from 'react';
import { gsap } from 'gsap';
import Typewriter from 'typewriter-effect';
import EditVideo from '../../assets/videos/edit.mp4';
import EditImage from '../../assets/images/blog.jpg';

const AboutUsHero = () => {
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    gsap.fromTo(
      '.hero-text',
      { opacity: 0, y: 50 },
      { opacity: 1, y: 0, duration: 1, ease: 'power2.out' }
    );
    gsap.fromTo(
      '.cta-button',
      { opacity: 0, y: 20 },
      { opacity: 1, y: 0, delay: 1.2, duration: 1, ease: 'power2.out' }
    );
  }, []);

  const handleVideoError = () => {
    setVideoLoaded(false);
  };

  const handleVideoLoad = () => {
    setVideoLoaded(true);
  };

  return (
    <section className="relative h-screen bg-black overflow-hidden">
      {!videoLoaded && !imageLoaded && (
        <img
          src={EditImage}
          alt="Fallback"
          className="absolute inset-0 object-cover w-full h-full"
          onLoad={() => setImageLoaded(true)}
          onError={() => setImageLoaded(true)}
        />
      )}
      <video
        className={`absolute inset-0 object-cover w-full h-full ${videoLoaded ? 'block' : 'hidden'}`}
        autoPlay
        muted
        loop
        playsInline
        onLoadedData={handleVideoLoad}
        onError={handleVideoError}
      >
        <source src={EditVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
        <div className="text-center hero-text text-white px-6 md:px-12">
          <h1 className="text-4xl md:text-6xl lg:text-7xl font-bold mb-4 leading-tight">
            <Typewriter
              options={{
                strings: [
                  'Welcome to The Blog Aura',
                  'Innovating Technology',
                  'Creativity Unleashed',
                  'Your Tech Hub',
                  'Explore & Learn'
                ],
                autoStart: true,
                loop: true,
                delay: 75,
                cursor: '|',
              }}
            />
          </h1>
          <p className="text-lg md:text-xl mb-6">
            Where Technology Meets Creativity
          </p>
          <a
            href="#learn-more"
            className="cta-button px-6 py-3 bg-teal-600 text-white text-lg font-semibold rounded-lg shadow-lg hover:bg-teal-700 transition-all duration-300"
          >
            Learn More
          </a>
        </div>
      </div>
    </section>
  );
};

export default AboutUsHero;
