import React, { useState, useEffect } from 'react';
import { gsap } from 'gsap';
import Typewriter from 'typewriter-effect';
import ContactVideo from '../../assets/videos/contact.mp4'; // Path to your video
import ContactImage from '../../assets/images/swim.jpg'; // Path to your fallback image

const ContactUsHero = () => {
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    gsap.fromTo(
      '.hero-text',
      { opacity: 0, y: 50 },
      { opacity: 1, y: 0, duration: 1, ease: 'power2.out' }
    );
    gsap.fromTo(
      '.cta-button',
      { opacity: 0, y: 20 },
      { opacity: 1, y: 0, delay: 1.2, duration: 1, ease: 'power2.out' }
    );
  }, []);

  const handleVideoError = () => {
    setVideoLoaded(false);
  };

  const handleVideoLoad = () => {
    setVideoLoaded(true);
  };

  return (
    <section className="relative h-screen bg-black overflow-hidden">
      {!videoLoaded && !imageLoaded && (
        <img
          src={ContactImage}
          alt="Fallback"
          className="absolute inset-0 object-cover w-full h-full"
          onLoad={() => setImageLoaded(true)}
          onError={() => setImageLoaded(true)}
        />
      )}
      <video
        className={`absolute inset-0 object-cover w-full h-full ${videoLoaded ? 'block' : 'hidden'}`}
        autoPlay
        muted
        loop
        playsInline
        onLoadedData={handleVideoLoad}
        onError={handleVideoError}
      >
        <source src={ContactVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
        <div className="text-center hero-text text-white px-6 md:px-12">
          <h1 className="text-4xl md:text-6xl lg:text-7xl font-bold mb-4 leading-tight">
            <Typewriter
              options={{
                strings: [
                  'Get in Touch with Us',
                  'We’re Here to Help',
                  'Reach Out Anytime',
                  'Contact Us Today',
                  'We Value Your Feedback'
                ],
                autoStart: true,
                loop: true,
                delay: 75,
                cursor: '|',
              }}
            />
          </h1>
          <p className="text-lg md:text-xl mb-6">
            We’re eager to hear from you! Drop us a line and we’ll get back to you as soon as possible.
          </p>
          <a
            href="#contact-form"
            className="cta-button px-6 py-3 bg-teal-600 text-white text-lg font-semibold rounded-lg shadow-lg hover:bg-teal-700 transition-all duration-300"
          >
            Contact Us
          </a>
        </div>
      </div>
    </section>
  );
};

export default ContactUsHero;
