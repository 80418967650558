import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ReactGA from 'react-ga4'; // Import react-ga4 for Google Analytics

const root = ReactDOM.createRoot(document.getElementById('root'));

// Initialize Google Analytics with the Measurement ID
ReactGA.initialize('G-DGDEZG0BK2');

// Track the initial page load
ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });

root.render(
  <React.StrictMode>
      <App />
    {/* <ToastContainer /> */}
  </React.StrictMode>
);

// Measure performance
reportWebVitals();
