import React, { useState, useEffect } from 'react';
import { gsap } from 'gsap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    gsap.fromTo(
      '.nav-item',
      { opacity: 0, x: 20 },
      { opacity: 1, x: 0, stagger: 0.1, duration: 0.8, ease: 'power2.out' }
    );
  }, [menuOpen]);

  return (
    <header className="sticky top-0 bg-gradient-to-r from-gray-100 to-gray-200 text-gray-800 p-6 flex items-center justify-between shadow-md z-50">
      {/* Title */}
      <div className="text-3xl font-semibold tracking-tight ">
        <NavLink to="/" className="text-teal-600 hover:text-teal">
          The Blog Aura
        </NavLink>
      </div>

      {/* Menu Toggle Button */}
      <button
        className="md:hidden p-2 text-gray-600 hover:text-teal-600 transition-colors duration-300"
        onClick={() => setMenuOpen(prev => !prev)}
        aria-label="Toggle navigation"
      >
        <FontAwesomeIcon icon={menuOpen ? faTimes : faBars} className="h-6 w-6" aria-hidden="true" />
      </button>

      {/* Drawer Menu */}
      <nav className={`fixed top-0 right-0 h-full w-64 bg-gray-100 shadow-lg transition-transform transform ${menuOpen ? 'translate-x-0' : 'translate-x-full'} md:relative md:translate-x-0 md:w-auto md:bg-transparent md:shadow-none`}>
        <div className="flex justify-end p-4 md:hidden">
          <button
            className="p-2 text-gray-600 hover:text-teal-600"
            onClick={() => setMenuOpen(false)}
            aria-label="Close navigation"
          >
            <FontAwesomeIcon icon={faTimes} className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <ul className="flex flex-col space-y-4 p-6 md:flex-row md:space-x-6 md:space-y-0 md:p-0">
          <li className="nav-item transition-colors duration-300 hover:bg-gray-300 rounded-lg p-2">
            <NavLink
              to="/"
              className={({ isActive }) =>
                isActive
                  ? 'text-teal-600 border-b-2 border-teal-600'
                  : 'text-gray-800 hover:text-teal-600'
              }
            >
              Home
            </NavLink>
          </li>
          <li className="nav-item transition-colors duration-300 hover:bg-gray-300 rounded-lg p-2">
            <NavLink
              to="/about"
              className={({ isActive }) =>
                isActive
                  ? 'text-teal-600 border-b-2 border-teal-600'
                  : 'text-gray-800 hover:text-teal-600'
              }
            >
              About Us
            </NavLink>
          </li>
          <li className="nav-item transition-colors duration-300 hover:bg-gray-300 rounded-lg p-2">
            <NavLink
              to="/contact"
              className={({ isActive }) =>
                isActive
                  ? 'text-teal-600 border-b-2 border-teal-600'
                  : 'text-gray-800 hover:text-teal-600'
              }
            >
              Contact Us
            </NavLink>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
