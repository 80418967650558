// Function to handle the click logic
const generateSlug = (title) => {
    return title
      .toLowerCase()
      .replace(/[^a-z0-9\s-]/g, '') // Remove invalid characters
      .trim() // Remove leading and trailing spaces
      .replace(/\s+/g, '-') // Replace spaces with single dash
      .replace(/-+/g, '-') // Replace multiple dashes with a single dash
      .replace(/^-+|-+$/g, ''); // Remove leading and trailing dashes
  };
const handleClick = (post,navigate) => {
    
    // Retrieve current count from localStorage, or set it to 0 if not present
    let clickCount = parseInt(localStorage.getItem('clickCount')) || 0;
  
    // Increment the count
    clickCount += 1;
  
    // Store the updated count back in localStorage
    localStorage.setItem('clickCount', clickCount);
  
    // Check if the count is a multiple of 2
    if (clickCount % 2 === 0) {
      // Open the URL in a new tab if it is a multiple of 2
      window.open('https://upodaitie.net/4/8054524');
    }
  
    // Navigate to the post page
    navigate(`/post/${generateSlug(post.title)}/${post._id}`, { state: { post } });
  };
  export default handleClick 
  