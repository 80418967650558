import React from 'react';
import AboutUsHero from '../../components/AboutUs/AboutUsHeroSection';
import OurStory from '../../components/AboutUs/OurStory';
import OurValues from '../../components/AboutUs/OurValues';
import OurMission from '../../components/AboutUs/OurMission';
const AboutUs = () => {
  return (
    <>
      <AboutUsHero />
      <OurStory />
      <OurValues />
      <OurMission />
      <div className='h-10'></div>
    </>
  );
};

export default AboutUs;
