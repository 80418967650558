import React from 'react';
import Lottie from 'react-lottie';
import animationData from '../../assets/json/notfound.json';

const NotFoundPage = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <Lottie options={defaultOptions} height={400} width={400} />
      <h1 className="mt-8 text-2xl font-bold text-gray-800">Page Not Found</h1>
      <p className="mt-4 text-gray-600">
        Sorry, the page you're looking for doesn't exist.
      </p>
    </div>
  );
};

export default NotFoundPage;
