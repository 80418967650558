import React from 'react';

const OurMission = () => {
  return (
    <section className="py-12 bg-gray-100">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold mb-6">Our Mission</h2>
        <p className="text-lg mb-4">
          Our mission is to provide insightful, high-quality content that empowers and informs our readers. We strive to be a leading source of knowledge and inspiration, making a positive impact through every blog post we publish.
        </p>
      </div>
    </section>
  );
};

export default OurMission;
