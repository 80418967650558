import React, { useEffect, useState } from 'react';
import { gsap } from 'gsap';
import { Typewriter } from 'react-simple-typewriter';
import tech from '../../assets/videos/tech.mp4';
import placeholder from '../../assets/images/tech.jpg'; // Fallback image

const HeroSection = () => {
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  useEffect(() => {
    gsap.fromTo(
      '.hero-title',
      { opacity: 0, y: -50 },
      { opacity: 1, y: 0, duration: 1.5, ease: 'power4.out' }
    );
    gsap.fromTo(
      '.hero-subtitle',
      { opacity: 0, y: 30 },
      { opacity: 1, y: 0, duration: 1.5, delay: 0.5, ease: 'power4.out' }
    );
    gsap.fromTo(
      '.cta-button',
      { opacity: 0, scale: 0.8 },
      { opacity: 1, scale: 1, duration: 0.8, delay: 1, ease: 'back.out(1.7)' }
    );
  }, []);

  return (
    <section className="relative h-screen overflow-hidden bg-gray-900">
      {/* Background Video */}
      <video
        className={`absolute top-0 left-0 w-full h-full object-cover transition-opacity ${isVideoLoaded ? 'opacity-100' : 'opacity-0'}`}
        autoPlay
        loop
        muted
        onCanPlayThrough={() => setIsVideoLoaded(true)}
        onError={() => setIsVideoLoaded(false)}
        aria-label="Blog Background Video"
      >
        <source src={tech} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      {/* Fallback Image */}
      {!isVideoLoaded && (
        <div className="absolute top-0 left-0 w-full h-full bg-cover bg-center" style={{ backgroundImage: `url(${placeholder})` }}></div>
      )}

      {/* Overlay */}
      <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-t from-black via-transparent to-black opacity-60"></div>

      {/* Decorative Elements */}
      <div className="absolute top-0 left-0 w-full h-full">
        <svg className="absolute top-0 left-0 w-full h-full opacity-10" viewBox="0 0 200 200" fill="none">
          <circle cx="100" cy="100" r="100" fill="url(#gradient)" />
          <defs>
            <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
              <stop offset="0%" stopColor="rgba(255,255,255,0.1)" />
              <stop offset="100%" stopColor="rgba(0,0,0,0.1)" />
            </linearGradient>
          </defs>
        </svg>
      </div>

      {/* Content */}
      <div className="relative z-10 flex flex-col justify-center items-center h-full p-6 md:p-12 text-white text-center">
        <div className="hero-title text-4xl md:text-5xl font-extrabold leading-tight mb-4 max-w-4xl mx-auto text-shadow-lg">
          <Typewriter
            words={[
              'Welcome to The Blog Aura!',
              'Explore the Latest Trends in Blogging',
              'Get Insights and Tips from Experts',
              'Join Our Community of Readers and Writers'
            ]}
            loop={true}
            cursor
            cursorStyle='|'
            typeSpeed={70}
            deleteSpeed={50}
            delaySpeed={2000}
          />
        </div>
        <p className="hero-subtitle text-lg md:text-xl mb-8 max-w-4xl mx-auto">
          Discover our latest blog posts, expert advice, and trends in the world of blogging.
        </p>
        <a
          href="#explore"
          className="cta-button bg-gradient-to-r from-teal-500 to-teal-600 hover:from-teal-600 hover:to-teal-700 text-white font-semibold py-3 px-8 rounded-lg shadow-lg transition-transform transform hover:scale-105 duration-300 max-w-xs mx-auto"
        >
          Explore Now
        </a>
      </div>
    </section>
  );
};

export default HeroSection;
