import React, { useState } from 'react';
import apiClient from '../../services/apiClient';
import { ENDPOINTS } from '../../services/endpoint';
import { toast } from 'react-toastify';  // Import toast for notifications

const ContactForm = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);

  const clearAllFields = () => {
    setName('');
    setEmail('');
    setMessage('');
  };

  const sendMessageToUs = async (e) => {
    e.preventDefault(); // Prevents the default form submission behavior
    setLoading(true); // Start loading

    try {
      await apiClient.post(ENDPOINTS.SEND_MESSAGE, {
        message,
        email,
        name
      });
      toast.success('Message sent successfully!');
      clearAllFields();
    } catch (error) {
      toast.error('Failed to send message. Please try again later.');
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <section className="py-16 px-8 bg-white" id="contact-form">
      <div className="container mx-auto">
        <h2 className="text-3xl md:text-4xl font-bold text-center mb-8">Send Us Your Feedback</h2>
        <form onSubmit={sendMessageToUs} className="max-w-lg mx-auto space-y-6">
          <input
            type="text"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="w-full p-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-teal-500"
          />
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full p-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-teal-500"
          />
          <textarea
            placeholder="Message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className="w-full p-4 border border-gray-300 rounded-lg h-40 focus:outline-none focus:ring-2 focus:ring-teal-500"
          ></textarea>
          <div className="flex justify-center">
            <button
              type="submit"
              className="bg-teal-500 hover:bg-teal-400 text-white font-bold py-3 px-6 rounded-md transition-colors"
              disabled={loading}
            >
              {loading ? 'Sending...' : 'Send Message'}
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default ContactForm;
