import React from 'react';

const CheckThisOutButton = () => {
  const handleClick = () => {
    window.open('https://upodaitie.net/4/8054524', '_blank');
  };

  return (
    <button
      onClick={handleClick}
      className="bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 text-white font-bold py-3 px-8 rounded-full shadow-lg hover:from-teal-500 hover:to-teal-700 hover:shadow-2xl transition duration-300 transform hover:scale-110 focus:outline-none focus:ring-4 focus:ring-teal-300 focus:ring-opacity-50"
    >
      ✨ Check This Out! ✨
    </button>
  );
};

export default CheckThisOutButton;
