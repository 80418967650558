import React, { useState, useEffect } from 'react';
import CoverPhoto from '../../assets/images/cover.webp';
import SEO from '../Seo/Seo';

const Countdown = ({ onComplete }) => {
  const [timeLeft, setTimeLeft] = useState({});
  const [isLaunchDayPassed, setIsLaunchDayPassed] = useState(false);

  const launchDate = new Date('2024-09-21T00:00:00');

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      const difference = launchDate - now;

      if (difference <= 0) {
        clearInterval(interval);
        setIsLaunchDayPassed(true);
        onComplete(); // Notify App.js that countdown is done
      } else {
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((difference / (1000 * 60)) % 60);
        const seconds = Math.floor((difference / 1000) % 60);
        setTimeLeft({ days, hours, minutes, seconds });
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [launchDate, onComplete]);

  if (isLaunchDayPassed) {
    return null;
  }

  const seoProps = {
    title: "The Blog Aura",
    description: "Explore insightful, easy-to-understand articles on topics like emerging tech trends, self-improvement tips, small business growth strategies, and budget-friendly travel hacks. Stay ahead with actionable advice and relatable content from The Blog Aura.",
    keywords: "emerging tech trends, self-improvement tips, small business growth, budget travel hacks, wellness advice, passive income ideas, simple relationship tips, trending lifestyle blogs",
    url: "https://theblogaura.com/",
    image: "%PUBLIC_URL%/logo192.png"
  };

  return (
    <>
      <SEO {...seoProps} />
      <div
        className="relative flex items-center justify-center min-h-screen bg-cover bg-center"
        style={{ backgroundImage: `url(${CoverPhoto})` }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-60"></div>
        <div className="relative z-10 text-center text-white p-6">
          <h1 className="text-3xl md:text-4xl lg:text-6xl font-bold text-teal-400">
            Get Ready for The Blog Aura
          </h1>
          <p className="text-base md:text-lg lg:text-2xl mt-4">
            Launching in:
          </p>

          {/* Adjust the flex settings for responsive behavior */}
          <div className="flex justify-center mt-8 space-x-2 sm:space-x-4">
            <div className="flex flex-col items-center bg-teal-500 rounded-lg p-2 sm:p-4 w-16 sm:w-20 md:w-24">
              <span className="text-2xl sm:text-3xl md:text-5xl font-bold">
                {timeLeft.days || '0'}
              </span>
              <p className="mt-1 text-xs sm:text-sm md:text-lg">Days</p>
            </div>
            <div className="flex flex-col items-center bg-teal-500 rounded-lg p-2 sm:p-4 w-16 sm:w-20 md:w-24">
              <span className="text-2xl sm:text-3xl md:text-5xl font-bold">
                {timeLeft.hours || '0'}
              </span>
              <p className="mt-1 text-xs sm:text-sm md:text-lg">Hours</p>
            </div>
            <div className="flex flex-col items-center bg-teal-500 rounded-lg p-2 sm:p-4 w-16 sm:w-20 md:w-24">
              <span className="text-2xl sm:text-3xl md:text-5xl font-bold">
                {timeLeft.minutes || '0'}
              </span>
              <p className="mt-1 text-xs sm:text-sm md:text-lg">Minutes</p>
            </div>
            <div className="flex flex-col items-center bg-teal-500 rounded-lg p-2 sm:p-4 w-16 sm:w-20 md:w-24">
              <span className="text-2xl sm:text-3xl md:text-5xl font-bold">
                {timeLeft.seconds || '0'}
              </span>
              <p className="mt-1 text-xs sm:text-sm md:text-lg">Seconds</p>
            </div>
          </div>

          <p className="text-sm md:text-lg mt-6">
            We can't wait to share what we've been working on with you!
          </p>
        </div>
      </div>
    </>
  );
};

export default Countdown;
