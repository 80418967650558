import React, { useEffect, useRef, useState } from 'react';
import PostCard from './PostCard';
import ShimmerPlaceHolder from '../Shimmer/Shimmer';
import '../../style/CustomScroll.css';
import gsap from 'gsap';
import apiClient from '../../services/apiClient';
import { ENDPOINTS } from '../../services/endpoint';

const RelatedPosts = ({ category, Keywords }) => {
  const sectionRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [relatedPost, setRelatedPost] = useState([]);

  useEffect(() => {
    console.log("catagory is ",category,"Kewords are ",Keywords)
    const fetchRelatedPost = async () => {
      try {
        const url = ENDPOINTS.GET_RELATED_POSTS({
          limit: 30, // Adjust limit as needed
          category,
          keywords: Keywords,
        });
        console.log("url is ",url)
        const response = await apiClient.get(url);
        if (response.status === 200) {
          const data = response.data.data.posts;
          console.log("Data is ",data)
          setRelatedPost(data);
        } else {
          throw new Error('Failed to fetch related posts');
        }
      } catch (error) {
        console.error('Error fetching related posts:', error);
        // Optionally set relatedPost to an empty array or handle error state
      } finally {
        setLoading(false);
      }
    };

    fetchRelatedPost();

    // GSAP animation
    gsap.fromTo(
      sectionRef.current,
      { opacity: 0, y: 50 },
      { opacity: 1, y: 0, duration: 1, ease: 'power2.out' }
    );
  }, [category, Keywords]);

  return (
    <section ref={sectionRef} className="py-12 px-6">
      <div className="flex items-center justify-between mb-8">
      <h2 className="text-3xl font-bold text-teal-600 relative inline-block group">
    Related Posts
    <span className="absolute left-0 right-0 bottom-0 mx-auto h-0.5 bg-teal-600 w-1/3 transition-all duration-300 group-hover:w-full"></span>
  </h2>
        </div>
      <div className="flex overflow-x-auto overflow-y-hidden pb-6 space-x-6 custom-scrollbar">
  {loading ? (
    <ShimmerPlaceHolder />
  ) : (
    Array.isArray(relatedPost) && relatedPost.length > 0 ? (
      relatedPost.map((post, index) => (
        <PostCard key={post._id} post={post} index={index} />
      ))
    ) : (
      <p className="text-gray-600">No related posts found.</p>
    )
  )}
</div>

    </section>
  );
};

export default RelatedPosts;
