import React from 'react';

const ShimmerVerticalPlaceholder = () => {
  return (
    <div className="bg-white p-4 shadow-lg rounded-lg mb-6 animate-pulse">
      <div className="w-full h-40 bg-gray-300 rounded-t-lg"></div>
      <div className="mt-4 space-y-3">
        <div className="h-6 bg-gray-300 rounded"></div>
        <div className="h-4 bg-gray-300 rounded"></div>
        <div className="h-4 bg-gray-300 rounded w-3/4"></div>
      </div>
    </div>
  );
};

const ShimmerGrid = ({ items }) => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
      {Array.from({ length: items }).map((_, index) => (
        <ShimmerVerticalPlaceholder key={index} />
      ))}
    </div>
  );
};

export default ShimmerGrid;
