import React, { useState, useEffect } from 'react';
import PostCardVertical from './PostCardVertical';
import Pagination from '../Pagination/Pagination';
import CategoryList from '../Catagory/CatagoryList';
import ShimmerGrid from '../Shimmer/ShimmerVertical';
import apiClient from '../../services/apiClient';
import { ENDPOINTS } from '../../services/endpoint';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faExclamationCircle, faListAlt } from '@fortawesome/free-solid-svg-icons';


const AllPosts = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [activeCategory, setActiveCategory] = useState('ALL');
  const [categories, setCategories] = useState([]);
  const [posts, setPosts] = useState([]);
  const [totalPages, setTotalPages] = useState(0);

  const postsPerPage = 12;

  useEffect(() => {
    fetchCategories();
    fetchPosts();
  }, [currentPage, activeCategory]);

  const fetchCategories = async () => {
    try {
      const response = await apiClient.get(ENDPOINTS.GET_ALL_CATEGORIES({ limit: 0 }));
      if (response.status === 200) {
        const data = response.data.data;
        setCategories([{ category: 'ALL' }, ...data.map(cat => ({ ...cat, category: cat.category }))]);
      } else {
        toast.error('Something went wrong, please try again.');
      }
    } catch (e) {
      toast.error('Error occurred while fetching categories.');
    }
  };

  const fetchPosts = async () => {
    setLoading(true);
    try {
      const url = ENDPOINTS.GET_ALL_POSTS({
        page: currentPage,
        limit: postsPerPage,
        category: activeCategory,
      });
      const response = await apiClient.get(url);
      if (response.status === 200) {
        setPosts(response.data.data.posts);
        setTotalPages(response.data.data.totalPages);
      } else {
        setPosts([]);
      }
    } catch (e) {
      setPosts([])
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="py-12 px-6" id="explore">
      <h2 className="text-3xl font-bold text-teal-600 relative inline-block group">
        ALL Posts
        <span className="absolute left-0 right-0 bottom-0 mx-auto h-0.5 bg-teal-600 w-1/3 transition-all duration-300 group-hover:w-full"></span>
      </h2>
      <CategoryList
        categories={categories}
        activeCategory={activeCategory}
        setActiveCategory={setActiveCategory}
        setCurrentPage={setCurrentPage}
      />
      <div className="my-8">
        {loading ? (
          <ShimmerGrid items={postsPerPage} />
        ) : posts.length === 0 ? (
          <div className="text-center py-6">
          <FontAwesomeIcon icon={faExclamationCircle} className="text-4xl text-gray-500 mb-4" />
          <p className="text-lg text-teal-700">No posts available at the moment.</p>
        </div>
        ) : (
            
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
              {posts.map((post) => (
                <PostCardVertical key={post._id} post={post} />
              ))}
            </div>
        )}
      </div>
      {posts.length > 0 && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={setCurrentPage}
        />
      )}
    </section>
  );
};

export default AllPosts;
