const ContactInfo = () => {
    return (
      <section className="py-16 px-8 bg-gray-100">
        <div className="container mx-auto text-center">
          <h2 className="text-3xl md:text-4xl font-bold mb-8">Contact Information</h2>
          <div className="bg-white p-6 rounded-lg shadow-lg mx-auto max-w-md">
            <h3 className="text-xl font-semibold mb-4">Email Us</h3>
            <p className="text-lg text-gray-700">Feel free to reach out to us via email:</p>
            <a href="mailto:info@yourwebsite.com" className="text-teal-600 font-semibold text-lg mt-4 inline-block">
              info@yourwebsite.com
            </a>
          </div>
        </div>
      </section>
    );
  };
  
  export default ContactInfo;
  