import React from 'react';
import HeroSection from '../../components/Hero/Hero';
import TrendingPosts from '../../components/Posts/TrendingPosts';
import RecentPosts from '../../components/Posts/RecentPosts';
import AllPosts from '../../components/Posts/AllPosts';
import SEO from '../../components/Seo/Seo';
const HomeScreen = () => {

  const seoProps = {
    title: "The Blog Aura",
    description: "Explore insightful, easy-to-understand articles on topics like emerging tech trends, self-improvement tips, small business growth strategies, and budget-friendly travel hacks. Stay ahead with actionable advice and relatable content from The Blog Aura.",
    keywords: "emerging tech trends, self-improvement tips, small business growth, budget travel hacks, wellness advice, passive income ideas, simple relationship tips, trending lifestyle blogs",
    url: "https://theblogaura.com/",
    image: "%PUBLIC_URL%/logo192.png"
    };

  
  return (
    <>
    <SEO
    {...seoProps}
    />
        <div>
      {/* Render the Hero Section */}
      <HeroSection />
      
      {/* Other content can go here */}
      <TrendingPosts />
      <RecentPosts/>
      <AllPosts/>
      
    </div>
    </>

  );
};

export default HomeScreen;
