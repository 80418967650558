import React from 'react';
import ContactUsHero from '../../components/ContactUs/ContactUsHeroSection';
import ContactInfo from '../../components/ContactUs/ContactInfo';
import ContactForm from '../../components/ContactUs/ContactForm';
import ContactCallToAction from '../../components/ContactUs/CallToAction';
import SEO from '../../components/Seo/Seo';

const ContactUs = () => {
  const seoProps = {
    title: "Contact Us - The Blog Aura",
    description: "Get in touch with The Blog Aura for any inquiries, feedback, or collaboration opportunities. Reach out through our contact form or directly via email.",
    keywords: "Contact, The Blog Aura, Email, Message, Customer Support, Feedback, Inquiry, Collaboration, Get in Touch",
    url: "https://theblogaura.com/contact",
    image: "%PUBLIC_URL%/logo192.png"
  };
  
  return (
    <>
    <SEO
    {...seoProps}
    />
      <ContactUsHero />
      <ContactInfo />
      <ContactForm />
      <ContactCallToAction />
      <div className='h-10'></div>
    </>
  );
};

export default ContactUs;
