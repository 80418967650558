// src/api/apiClient.js
import axios from 'axios';
import API_BASE_URL from './config';

// Email and password for basic authentication
const email = "theblogaura@gmail.com";
const password = "poweredbyimpactminds";

// Function to encode credentials and create the Authorization header
const getBasicAuthHeader = (email, password) => {
    const credentials = `${email}:${password}`;
    const encodedCredentials = btoa(credentials);  // Encode to Base64
    return `Basic ${encodedCredentials}`;
}

// Create an instance of Axios
const apiClient = axios.create({
    baseURL: API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

apiClient.interceptors.request.use(config => {
    config.headers['Authorization'] = getBasicAuthHeader(email, password);
    return config;
}, error => {
    return Promise.reject(error);
});

export default apiClient;
