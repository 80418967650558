import React, { useState } from 'react';
import apiClient from '../../services/apiClient';
import { ENDPOINTS } from '../../services/endpoint';
import { toast} from 'react-toastify';

const ContactCallToAction = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false); // Add loading state

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true when starting the request
    try {
      // Send the email to your backend or a service like Mailchimp
      const response = await apiClient.post(ENDPOINTS.SUBSCRIBE_NEWSLETTER(email));
      if (response.status === 200) {
        toast.success('Subscription successful! Check your email for confirmation.');
        setEmail('');
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error('Subscription failed. Please try again.');
    } finally {
      setLoading(false); // Set loading to false after request completes
    }
  };

  return (
    <section className="bg-teal-500 text-white py-12 px-36">
      <div className="container mx-auto px-4 text-center">
        <h2 className="text-3xl font-bold mb-4">Stay Updated!</h2>
        <p className="text-lg mb-6">Subscribe to our newsletter for the latest updates and posts.</p>
        <form onSubmit={handleSubmit} className="flex flex-col sm:flex-row justify-center items-center">
          <input
            type="email"
            placeholder="Enter your email address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="p-3 rounded-lg border-2 border-teal-400 bg-white text-teal-700 placeholder-teal-500 focus:outline-none focus:border-teal-600 mb-4 sm:mb-0 sm:mr-4"
          />
          <button
            type="submit"
            className={`bg-teal-600 hover:bg-teal-700 text-white py-3 px-6 rounded-lg transition-colors ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
            disabled={loading} // Disable button when loading
          >
            {loading ? 'Subscribing...' : 'Subscribe'}
          </button>
        </form>
      </div>
    </section>
  );
};

export default ContactCallToAction;
