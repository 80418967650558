// src/api/endpoints.js
import API_BASE_URL from './config';

export const ENDPOINTS = {
    // Posts
    TEST_ROUTE: `${API_BASE_URL}/post`,
    ADD_POST: `${API_BASE_URL}/post/addPost`,
    GET_ALL_POSTS: ({ page = 1, limit = 5, sort_field = '', category = 'ALL' } = {}) => 
        `${API_BASE_URL}/post/getAllPosts?page=${page}&limit=${limit}&sort_field=${sort_field}&category=${category}`,
    GET_SINGLE_POST: (id) => `${API_BASE_URL}/post/getSinglePost?id=${id}`,
    GET_TRENDING_POSTS: ({ limit = 30 } = {}) => `${API_BASE_URL}/post/getTrendingPosts?limit=${limit}`,
    GET_RECENT_POSTS: ({ limit = 30 } = {}) => `${API_BASE_URL}/post/getRecentPosts?limit=${limit}`,
    GET_RELATED_POSTS: ({ limit = 5, category = '', keywords = [] } = {}) => {
        const keywordsParam = keywords.map(k => `&keywords=${encodeURIComponent(k)}`).join('');
        return `${API_BASE_URL}/post/getRelatedPosts?limit=${limit}&category=${category}${keywordsParam}`;
    },
    UPDATE_POST: (id) => `${API_BASE_URL}/post/updatePost?id=${id}`,
    DELETE_POST: (id) => `${API_BASE_URL}/post/deletePost?id=${id}`,

    // Admin
    LOGIN: `${API_BASE_URL}/admin/login`,
    ADD_ADMIN: `${API_BASE_URL}/admin/addAdmin`,
    EDIT_ADMIN: (id) => `${API_BASE_URL}/admin/editAdmin?id=${id}`,
    DELETE_ADMIN: (id) => `${API_BASE_URL}/admin/deleteAdmin/${id}`,
    GET_ALL_ADMINS: `${API_BASE_URL}/admin/getAllAdmins`,
    CHECK_TOKEN: `${API_BASE_URL}/admin/checkToken`,

    // Category
    TEST_CATEGORY_ROUTE: `${API_BASE_URL}/category/`,
    ADD_CATEGORY: `${API_BASE_URL}/category/addCategory`,
    GET_ALL_CATEGORIES: ({ limit = 10 } = {}) => `${API_BASE_URL}/category/getAllCategories?limit=${limit}`,
    GET_SINGLE_CATEGORY: (id) => `${API_BASE_URL}/category/getSingleCategory?id=${id}`,
    UPDATE_CATEGORY: (id) => `${API_BASE_URL}/category/updateCategory?id=${id}`,
    DELETE_CATEGORY: (id) => `${API_BASE_URL}/category/deleteCategory?id=${id}`,

    // Image
    UPLOAD_IMAGE: `${API_BASE_URL}/image/uploadImage`,

    // Contact
    SEND_MESSAGE: `${API_BASE_URL}/contact/sendMessage`,
    SUBSCRIBE_NEWSLETTER: (email)=>`${API_BASE_URL}/contact/subscribe?email=${email}`,
    UNSUBSCRIBE_NEWSLETTER:(email)=> `${API_BASE_URL}/contact/unsubscribe?email=${email}`,
    SEND_EMAIL_FOR_NEWSLETTER: `${API_BASE_URL}/contact/sendEmailForNewsletter`,

};
