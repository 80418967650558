import React from 'react';

const OurStory = () => {
  return (
    <section className="py-12 bg-gray-100">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold mb-6">Our Story</h2>
        <p className="text-lg mb-4">
          Our story began with a simple idea: to create a platform where insightful blog content could thrive. We started with a small team of passionate writers and developers, and have grown into a community of content creators dedicated to bringing valuable information to our readers.
        </p>
      </div>
    </section>
  );
};

export default OurStory;
