import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { gsap } from 'gsap';

const Footer = () => {
  React.useEffect(() => {
    gsap.fromTo('.footer-content', 
      { opacity: 0, y: 50 }, 
      { opacity: 1, y: 0, duration: 1, ease: 'power2.out' }
    );
  }, []);

  return (
    <footer className="bg-gray-900 text-white py-8 md:py-10 footer-content">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row md:justify-between md:items-start text-center md:text-left">
          <div className="mb-8 md:mb-0 md:w-1/3">
            <h2 className="text-2xl md:text-3xl font-extrabold text-teal-400 mb-4">The Blog Aura</h2>
            <ul className="list-none space-y-2">
              <li><a href="/" className="hover:text-teal-400 transition-colors">Home</a></li>
              <li><a href="/about" className="hover:text-teal-400 transition-colors">About</a></li>
              <li><a href="/contact" className="hover:text-teal-400 transition-colors">Contact</a></li>
              <li><a href="/privacy" className="hover:text-teal-400 transition-colors">Privacy Policy</a></li>
            </ul>
          </div>
          <div className="mb-8 md:mb-0 md:w-1/3">
            <h2 className="text-lg font-semibold mb-4">Follow Us</h2>
            <div className="flex justify-center md:justify-start space-x-6">
              <a href="https://www.facebook.com/profile.php?id=61565386873163" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faFacebookF} className="text-white hover:text-teal-400 transition-colors text-lg md:text-xl" />
              </a>
              <a href="https://x.com/theblogaura" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faTwitter} className="text-white hover:text-teal-400 transition-colors text-lg md:text-xl" />
              </a>
              <a href="https://www.instagram.com/blogaura" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faInstagram} className="text-white hover:text-teal-400 transition-colors text-lg md:text-xl" />
              </a>
              <a href="https://www.linkedin.com/company/the-blog-aura" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faLinkedin} className="text-white hover:text-teal-400 transition-colors text-lg md:text-xl" />
              </a>
            </div>
          </div>
          <div className="md:w-1/3">
            <h2 className="text-lg font-semibold mb-4">Contact Us</h2>
            <p className="text-gray-400 hover:text-teal-400 transition-colors">
              <a href="mailto:theblogaura0@gmail.com">theblogaura0@gmail.com</a>
            </p>
          </div>
        </div>
        <div className="mt-8 border-t border-gray-700 pt-4 text-center">
          <p className="text-gray-400 text-sm">&copy; {new Date().getFullYear()} The Blog Aura. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
