import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes,useLocation } from 'react-router-dom';
import Header from './components/Header/Header';
import Countdown from './components/Countdwn/Countdown';
import Footer from './components/Footer/Footer';
import PostDetail from './components/Posts/PostDetail';
import AboutUs from './Pages/About/AboutUs';
import ContactUs from './Pages/Contact/ContactUs';
import NotFoundPage from './components/notfound/NotFound';
import PrivacyPolicy from './Pages/Privacy/PrivacyPolicy';
import { ToastContainer } from 'react-toastify';
import HomeScreen from './Pages/Home/Home';
import 'react-toastify/dist/ReactToastify.css';
import ReactGA from 'react-ga4';
import './style/globel.css';

const App = () => {
  const [showContent, setShowContent] = useState(false); // Track whether to show Header and Footer
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
  }, []); 

  const handleCountdownComplete = () => {
    setShowContent(true); // Show Header and Footer after countdown
  };

  return (
    <Router>
      <ToastContainer />
      {showContent ? (
        <Countdown onComplete={handleCountdownComplete} />
      ) : (
        <>
          <Header />
          <Routes>
            <Route path="/" element={<HomeScreen  />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/post/:title/:id" element={<PostDetail />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
          <Footer />
        </>
      )}
    </Router>
  );
};

export default App;
