import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import RelatedPosts from './RelatedPosts';
import { ENDPOINTS } from '../../services/endpoint';
import apiClient from '../../services/apiClient';
import { toast } from 'react-toastify';
import CheckThisOutButton from '../Button/CheckThisOutButton';
import SEO from '../Seo/Seo';
const PostDetail = () => {
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const params = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchPosts();
  }, []);

  const fetchPosts = async () => {
    try {
      const response = await apiClient.get(ENDPOINTS.GET_SINGLE_POST(params.id));
      if (response.status === 200) {
        const data = response.data.data;
        console.log("Language is ", data.language, data.language === "ur");
        
        setPost(data);
      } else {
        toast.error(response.data.message);
      }
    } catch (e) {
      toast.error('Failed to fetch post.');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="animate-pulse flex flex-col space-y-4 p-6">
        <div className="h-60 bg-gray-300 rounded-lg"></div>
        <div className="h-8 bg-gray-300 rounded"></div>
        <div className="h-4 bg-gray-300 rounded"></div>
        <div className="h-4 bg-gray-300 rounded w-5/6"></div>
        <div className="h-4 bg-gray-300 rounded w-3/4"></div>
      </div>
    );
  }

  if (!post) {
    return <div className="text-center text-xl font-semibold py-20">Post not found</div>;
  }

  const formattedDate = new Date(post.createdAt).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
  
  const articleStructuredData = {
    "@context": "https://schema.org",
    "@type": "Article",
    "headline": post.title || 'Sample Blog Title',
    "description": post.metaDescription ? post.metaDescription.substring(0, 160) : 'This is a sample blog description.',
    "image": {
      "@type": "ImageObject",
      "url": post.thumbnail || 'https://via.placeholder.com/1200x630',
      "width": 1200,
      "height": 630
    },
   
    "publisher": {
      "@type": "Organization",
      "name": "The Blog Aura",
      "logo": {
        "@type": "ImageObject",
        "url": "https://via.placeholder.com/200x60" // Replace with actual logo URL if available
      }
    },
    "datePublished": post.createdAt || new Date().toISOString(),
    "dateModified": post.updatedAt || new Date().toISOString()
  };
  function sanitizeAndHandleEmptyTags(content) {
    return content ; 
  }

  return (
    <>
   
    <SEO
    title={post.title || 'Sample Blog Title'}
    description={post.metaDescription ? post.metaDescription.substring(0, 160) : 'This is a sample blog description.'}
    keywords={post.keywords || 'blog, sample, SEO'}
    url={window.location.href}
    image={post.thumbnail || 'https://via.placeholder.com/1200x630'}
    structuredData={articleStructuredData}
  />
  
      <section  className="mx-auto py-12 px-6 bg-white shadow-lg rounded-lg relative z-10 main-content">
        <img
          src={post.thumbnail}
          alt="Post Image"
          className="w-full h-72 object-contain rounded-3xl "
          dir={post.language === "ur" ? "rtl" : "ltr"} // Corrected language check and direction
          />
        <div className="p-6">
        <h2 className="text-4xl font-bold mb-4 " 
  dir={post.language === "ur" ? "rtl" : "ltr"} // Corrected language check and direction
  >{post.title}</h2>
        <div
            className="text-lg leading-relaxed text-gray-700 mb-4" 
            dir={post.language === "ur" ? "rtl" : "ltr"} // Corrected language check and direction

            dangerouslySetInnerHTML={{ __html: sanitizeAndHandleEmptyTags(post.content) }}
          />
    <div className="flex justify-center my-6">
            <CheckThisOutButton />
          </div>
          <div className="text-sm text-teal-600">
            <span>Powered by The Blog Aura</span> • <span>{formattedDate}</span>
          </div>
        </div>
        <RelatedPosts category={post.category} Keywords={post.keywords} />
      </section>
    </>
  );
};

export default PostDetail;
