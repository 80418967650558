import React from 'react';
import 'react-lazy-load-image-component/src/effects/blur.css';
import handleClick from '../../utils/HandelClick';
import { useNavigate } from 'react-router-dom';

const PostCardVertical = ({ post }) => {
  const navigate = useNavigate();

  // Function to truncate meta description to 160 characters
  const truncateDescription = (description) => {
    if (description.length > 160) {
      return description.substring(0, 160) + '...';
    }
    return description;
  };

  return (
    <div
      className="bg-white p-4 shadow-lg rounded-lg hover:shadow-2xl transition-shadow duration-300 transform cursor-pointer flex flex-col h-full"
      onClick={() => handleClick(post, navigate)}
    >
      <div className="w-full h-40 overflow-hidden rounded-t-lg">
        <img
          src={post.thumbnail}
          alt={post.imageAltText}
          className="w-full h-full object-cover"
        />
      </div>
      <div className="mt-4 flex-1">
        {/* Full title displayed */}
        <h3 className="text-lg font-semibold">{post.title}</h3>
        
        {/* Truncated meta description */}
        <p className="text-sm text-gray-600 mt-2">
          {truncateDescription(post.metaDescription)}
        </p>
      </div>

      {/* Footer content at the bottom */}
      <div className="mt-4 flex justify-between items-center">
        <span className="text-teal-600 font-semibold">The Blog Aura</span>
        <span className="text-gray-500 text-sm">{new Date(post.createdAt).toLocaleDateString()}</span>
      </div>
    </div>
  );
};

export default PostCardVertical;
