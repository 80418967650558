import React from 'react';

const ShimmerPlaceHolder = () => {
  return (
    <div className="flex space-x-6">
      {Array.from({ length: 4 }).map((_, index) => (
        <div
          key={index}
          className="min-w-[300px] md:min-w-[250px] lg:min-w-[300px] bg-white p-4 animate-pulse shadow-lg rounded-lg"
        >
          <div className="w-full h-40 bg-gray-300 rounded-t-lg"></div>
          <div className="mt-4 space-y-2">
            <div className="h-6 bg-gray-300 rounded"></div>
            <div className="h-4 bg-gray-300 rounded"></div>
            <div className="h-4 bg-gray-300 rounded w-2/4"></div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ShimmerPlaceHolder;
