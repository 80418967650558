import React, { useEffect, useRef, useState } from 'react';
import PostCard from './PostCard';
import ShimmerPlaceHolder from '../Shimmer/Shimmer';
import '../../style/CustomScroll.css';
import gsap from 'gsap';
import { ENDPOINTS } from '../../services/endpoint';
import { toast } from 'react-toastify';
import apiClient from '../../services/apiClient';

const RecentPosts = () => {
  const sectionRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [recentPosts,setRecentPosts]=useState(null)



  useEffect(() => {
    gsap.fromTo(
      sectionRef.current,
      { opacity: 0, y: 50 },
      { opacity: 1, y: 0, duration: 1, ease: 'power2.out' }
    );
    fetchRecentPosts()
  }, []);
  const fetchRecentPosts=async()=>{
    try{
      setLoading(true)
  const response=await apiClient.get(ENDPOINTS.GET_RECENT_POSTS({limit:30}))
  if(response.status===200){
   const data=response.data.data
    setRecentPosts(data)
    console.log("Recent Posts Data is ",data)
  
    }
    else{
      toast.error(response.data.message)
    }
    }
    catch(e){
      console.log("Error happened",e)
    }
    finally{
      setLoading(false)
    }
  
  }
 

  return (
    <section ref={sectionRef} className="py-12 px-6">
      <div className="flex items-center justify-between mb-8">
  <h2 className="text-3xl font-bold text-teal-600 relative inline-block group">
    Recent Posts
    <span className="absolute left-0 right-0 bottom-0 mx-auto h-0.5 bg-teal-600 w-1/3 transition-all duration-300 group-hover:w-full"></span>
  </h2>
</div>

      <div className="flex overflow-x-auto overflow-y-hidden pb-6 space-x-6 custom-scrollbar">
        {loading ? (
          <ShimmerPlaceHolder />
        ) : (
          recentPosts.map((post, index) => (
            <PostCard key={post._id} post={post} index={index} />
          ))
        )}
      </div>
    </section>
  );
};

export default RecentPosts;
